(/*jshint unused:false,supernew:true*/function(W, U) {
  'use strict';

  var _ = W._,
      $ = W.jQuery;

  W.Crossbar = W.io = (new function() {
    if (!W.session_id) {
      throw new Error('Missing session id');
    }
    var self = W.IO({token: W.session_id}),
        _proxy = $({}),
        _reject,
        _resolve,
        _session,
        _auth,
        _subs = {},
        lotsToReconnect = {},
        lotCache = {},
        _isReady = false,
        _promise = new W.Promise(function(resolve, reject) {
          _resolve = resolve;
          _reject = reject;
        });

    self.ioOn = self.on;

    $.extend(self, {
      on: function(type, fn) { _proxy.on(type, fn); return self; },

      isReady: function() {
        return _isReady;
      },

      _subscribe: function(topic, handler) {
        self.subscribe(topic, handler).then(function(sub) { _subs[topic] = sub; });
      },

      /*_unsubscribe: function(topic) {
        var sub = _subs[topic];
        if (sub) { _session.unsubscribe(sub); }
      },*/

      addLot: function(wid, id) {
        console.log('subscribed to lot ' + id);
        if (!lotsToReconnect[wid]) lotsToReconnect[wid] = [];
        lotsToReconnect[wid].push(id);
        // self._subscribe('api:' + wid + '.lot.extended.' + id, function(args) { _proxy.trigger('extended', [id, args[0]]); });
        // self._subscribe('api:' + wid + '.lot.newbid.' + id, function(args) { _proxy.trigger('bid', [id, args[0]]); });
        // self._subscribe('api:' + wid + '.lot.closed.' + id, function(args) { _proxy.trigger('lot_closed', [id]); });
        self._subscribe(wid+':lot:'+id, function(args) { 
          var data = args[0];

          // Add lot cache to check last call time
          if (data.call_time) {
            var callTime = moment(data.call_time);
            if (lotCache[id] && callTime.isBefore(lotCache[id])) {
              console.warn('Update is before last received message');
              return;
            }
            lotCache[id] = callTime;
          }
          var time = moment(data.end_time).tz(W.Timezone.zone_name),
              bid = {
                amount: data.amount,
                minimum_bid: parseFloat(data.minimum_bid),
                bidder_id: data.bidder_id,
                username: data.username,
                bid_increment: parseFloat(data.bid_increment),
                required: parseFloat(data.required),
                count: data.count,
                updated_at: moment(data.now).tz(W.Timezone.zone_name),
              },
              is_completed = data.is_completed || false;
            console.log(data);

          _proxy.trigger('extended', [id, time]);
          _proxy.trigger('bid', [id, bid]);
          if (is_completed) _proxy.trigger('lot_closed', [id]);
        })
        return self;
      },
      
      placeBid: function(wid, id, amt, bidType) {
        if (!self.isReady()) { throw new Error('Not connected to Crossbar'); }
        return self.call('placeBid', wid, id, bidType, amt);
      },

      then: function(cb) { _promise.then(cb); return self; },
      catch: function(cb) { _promise.catch(cb); return self; },

      open: function() {
        self.ioOn('time', function(args) {
          _proxy.trigger('time', args);
        });
        self.ioOn('connect', function() {
          _isReady = true;
          _resolve();
          _proxy.trigger('connect');
          if (!_.isEmpty(lotsToReconnect)) {
            _proxy.trigger('reconnect');
          }
        });
        self.ioOn('disconnect', function() {
          _proxy.trigger('disconnect');
        });
        self.ioOn('reconnect', function() {
          _proxy.trigger('reconnect');
        });
        return self.connect();
      }
    });

    self.on('reconnect', function() {
      _.forOwn(lotsToReconnect, function(lots, website_id) {
        if (lots.length) {
          self.reconnectRefresh(website_id, lots)
          .then(function(lots) {
            for (var i=0; i<lots.length; i++) {
              var data = lots[i];
              if (data) {
                var time = moment(data.end_time).tz(W.Timezone.zone_name),
                bid = {
                  amount: data.amount,
                  minimum_bid: parseFloat(data.minimum_bid),
                  bidder_id: data.bidder_id,
                  username: data.username,
                  bid_increment: parseFloat(data.bid_increment),
                  required: parseFloat(data.required),
                  count: data.count,
                  updated_at: moment(data.now).tz(W.Timezone.zone_name),
                },
                is_completed = data.now && data.end_time ? data.now > data.end_time : false;

                _proxy.trigger('extended', [data.id, time]);
                _proxy.trigger('bid', [data.id, bid]);
                if (is_completed) _proxy.trigger('lot_closed', [data.id]);
              }
            }
          });
        }
      });
    });

    return self;

  }());

}(self));
